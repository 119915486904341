import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import SEO from '../../components/SEO/SEO';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaLaptopCode, FaMobileAlt, FaSearch, FaPaintBrush, FaUsers } from 'react-icons/fa';

// Import project images
import Project1 from '../../assets/project1.png';
import Project2 from '../../assets/project2.png';
import Project3 from '../../assets/project3.png';
import Project4 from '../../assets/project4.png';

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animation happens only once when scrolling down
    });
  }, []);

  return (
    <section className="home-section">
      <SEO
        title="Home"
        description="Welcome to DiceMatrix, your Kansas City partner for professional web design and website management services. Let us elevate your online presence."
        image="https://dicematrix.cloud/home-banner.jpg"
        url="https://dicematrix.cloud/"
      />

      {/* Hero Section */}
      <div className="hero" data-aos="fade-in">
        <div className="hero-content">
          <h1>Transforming</h1>
          <h1>Your Digital Presence</h1>
          <p>Professional Web Design and Management Services in Kansas City</p>
          <div className="cta-buttons">
            <Link to="/services" className="btn primary-btn">Our Services</Link>
            <Link to="/contact" className="btn secondary-btn">Get Started</Link>
          </div>
        </div>
      </div>

      {/* Services Overview */}
      <div className="services-overview" data-aos="fade-up">
        <h2>What We Offer</h2>
        <div className="services-container">
          <div className="service-item">
            <Link to="/services/web-development">
              <FaLaptopCode className="service-icon" />
              <h3>Web Design & Management Services</h3>
              <p>Building responsive and robust websites tailored to your business needs.</p>
            </Link>
          </div>
          <div className="service-item">
            <Link to="/services/mobile-optimization">
              <FaMobileAlt className="service-icon" />
              <h3>Mobile Optimization</h3>
              <p>Ensuring your website looks great and functions seamlessly on all mobile devices.</p>
            </Link>
          </div>
          <div className="service-item">
            <Link to="/services/seo-services">
              <FaSearch className="service-icon" />
              <h3>SEO Services</h3>
              <p>Improving your website’s visibility on search engines to attract more visitors.</p>
            </Link>
          </div>
          <div className="service-item">
            <Link to="/services/graphic-design">
              <FaPaintBrush className="service-icon" />
              <h3>Graphic Design</h3>
              <p>Creating visually appealing graphics and layouts for your brand identity.</p>
            </Link>
          </div>
        </div>
      </div>

      {/* Portfolio Preview */}
      <div className="portfolio-preview" data-aos="fade-up">
        <h2>Our Work</h2>
        <div className="portfolio-container">
          <div className="portfolio-item">
            <img src={Project1} alt="Project One" loading="lazy" className="portfolio-image" />
            <div className="portfolio-overlay">
              <h4>Project One</h4>
              <p>A modern e-commerce website with seamless user experience.</p>
            </div>
          </div>
          <div className="portfolio-item">
            <img src={Project2} alt="Project Two" loading="lazy" className="portfolio-image" />
            <div className="portfolio-overlay">
              <h4>Project Two</h4>
              <p>A responsive mobile application for online bookings.</p>
            </div>
          </div>
          <div className="portfolio-item">
            <img src={Project4} alt="Project Four" loading="lazy" className="portfolio-image" />
            <div className="portfolio-overlay">
              <h4>Project Four</h4>
              <p>A creative portfolio website showcasing various designs.</p>
            </div>
          </div>
        </div>
        <Link to="/portfolio" className="btn view-more-btn">View More</Link>
      </div>

      {/* Testimonials */}
      <div className="testimonials" data-aos="fade-up">
        <h2>What Our Clients Say</h2>
        <div className="testimonials-container">
          <div className="testimonial-item">
            <FaUsers className="testimonial-icon" />
            <p>"DiceMatrix transformed our website into a user-friendly platform that has significantly increased our traffic and sales."</p>
            <h4>- Sarah K., Local Business Owner</h4>
          </div>
          <div className="testimonial-item">
            <FaUsers className="testimonial-icon" />
            <p>"Their SEO services are top-notch! We've seen a remarkable improvement in our search engine rankings."</p>
            <h4>- Mike L., Marketing Manager</h4>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
