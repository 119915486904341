// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';
import Services from './pages/Services/Services';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import WebDevelopment from './pages/Services/WebDevelopment/WebDevelopment';
import MobileOptimization from './pages/Services/MobileOptimization/MobileOptimization';
import SEOServices from './pages/Services/SEO/SEOServices';
import GraphicDesign from './pages/Services/GraphicDesign/GraphicDesign';
import Portfolio from './pages/Portfolio/Portfolio'; // Import Portfolio page
import ScrollToTop from './components/ScrollToTop/ScrollToTop'; // Import ScrollToTop
import { DarkModeProvider } from './contexts/DarkModeContext';
import './App.css';

function App() {
  return (
    <DarkModeProvider>
      <Router>
        <ScrollToTop /> {/* Ensure this is above the Routes */}
        <div className="App">
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              {/* Service Sub-Pages */}
              <Route path="/services/web-development" element={<WebDevelopment />} />
              <Route path="/services/mobile-optimization" element={<MobileOptimization />} />
              <Route path="/services/seo-services" element={<SEOServices />} />
              <Route path="/services/graphic-design" element={<GraphicDesign />} />
              {/* Portfolio Page */}
              <Route path="/portfolio" element={<Portfolio />} />
              {/* Other Pages */}
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              {/* Add more routes as needed */}
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </DarkModeProvider>
  );
}

export default App;
