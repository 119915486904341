// src/pages/Services/GraphicDesign/GraphicDesign.js
import React, { useEffect, useContext } from 'react';
import './GraphicDesign.css';
import SEO from '../../../components/SEO/SEO';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DarkModeContext } from '../../../contexts/DarkModeContext';

function GraphicDesign() {
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section className={`graphicdesign-section ${darkMode ? 'dark-mode' : ''}`}>
      <SEO
        title="Graphic Design"
        description="Elevate your brand with DiceMatrix's Graphic Design services in Kansas City. We create visually stunning graphics tailored to your business identity."
        image="https://dicematrix.cloud/graphic-design-banner.jpg" // Replace with your banner image URL
        url="https://dicematrix.cloud/services/graphic-design"
      />

      <h2 data-aos="fade-up">Graphic Design</h2>
      <div className="graphicdesign-content">
        <div className="graphicdesign-text" data-aos="fade-right">
          <p>
            First impressions matter. At DiceMatrix, our Graphic Design services are dedicated to crafting visually compelling designs that reflect your brand's identity and values. From logos and brochures to social media graphics and website visuals, we ensure every element is thoughtfully designed to resonate with your target audience.
          </p>
          <p>
            Our creative team combines artistic flair with strategic thinking to produce designs that not only look great but also serve a purpose. Whether you're looking to rebrand or create new marketing materials, we're here to bring your vision to life with creativity and precision.
          </p>
        </div>
        <div className="graphicdesign-image" data-aos="fade-left">
          <img src="https://source.unsplash.com/600x400/?graphic,design" alt="Graphic Design Services" loading="lazy" />
        </div>
      </div>
    </section>
  );
}

export default GraphicDesign;
