// src/pages/Services/WebDevelopment/WebDevelopment.js
import React, { useEffect, useContext } from 'react';
import './WebDevelopment.css';
import SEO from '../../../components/SEO/SEO';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DarkModeContext } from '../../../contexts/DarkModeContext';

function WebDevelopment() {
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section className={`webdev-section ${darkMode ? 'dark-mode' : ''}`}>
      {/* SEO Metadata */}
      <SEO
        title="Web Design & Management Services"
        description="Professional web design and management services for businesses. From creating stunning websites to managing and maintaining them, we handle it all."
        image="https://dicematrix.cloud/web-dev-management-banner.jpg"
        url="https://dicematrix.cloud/services/web-development"
      />

      {/* Hero Section */}
      <div className="webdev-hero">
        <h1>Web Design & Management Services</h1>
        <p>Transforming your online presence with tailored solutions that grow with your business.</p>
      </div>

      {/* Services Overview */}
      <section className="services-overview" data-aos="fade-up">
        <h2>Our Services</h2>
        <div className="services-container">
          <div className="service-item">
            <h3>Custom Web Design</h3>
            <p>
              Develop robust, scalable web solutions from the ground up. Using frameworks like Node.js, React, or Angular, we build fast, secure, and reliable websites tailored to your business needs and optimized for dedicated servers.
            </p>
          </div>
          <div className="service-item">
            <h3>Website Management</h3>
            <p>
              Simplify your operations with our management services. We handle updates, backups, content changes, and performance monitoring, ensuring your website runs efficiently while you focus on growing your business.
            </p>
          </div>
          <div className="service-item">
            <h3>E-commerce Solutions</h3>
            <p>
              Optimize and manage your existing e-commerce platforms like Shopify, Squarespace, WooCommerce, or BigCommerce. Our services ensure your online store provides a seamless shopping experience that drives sales and customer satisfaction.
            </p>
          </div>
          <div className="service-item">
            <h3>SEO Optimization</h3>
            <p>
              Maximize your online visibility with our comprehensive SEO services. From technical audits to keyword optimization, we help you rank higher and attract more customers to your site.
            </p>
          </div>
          <div className="service-item">
            <h3>Content Management Systems (CMS)</h3>
            <p>
              Streamline your website management with industry-leading CMS platforms like WordPress, Drupal, and Joomla. We’ll integrate and customize your CMS for ease of use, scalability, and efficient content updates.
            </p>
          </div>
          <div className="service-item">
            <h3>Building within Website Builders</h3>
            <p>
              Leverage platforms like Squarespace, Wix, and Webflow to create beautiful, functional websites. We customize templates, optimize SEO, and ensure mobile-friendly designs while maintaining your platform’s simplicity.
            </p>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us" data-aos="fade-right">
        <h2>Why Choose Us?</h2>
        <p>
          We understand that your website is more than just a digital presence—it's a vital part of your business. Here's what sets us apart:
        </p>
        <ul>
          <li>Custom solutions tailored to your business goals.</li>
          <li>Proven expertise with the latest tools and technologies.</li>
          <li>Reliable support and transparent communication throughout the process.</li>
          <li>Scalable designs that grow alongside your business.</li>
          <li>Flexible pricing options to fit your budget.</li>
        </ul>
      </section>

      {/* Call to Action */}
      <section className="call-to-action" data-aos="fade-up">
        <h2>Ready to Elevate Your Online Presence?</h2>
        <p>Let’s create something extraordinary together. Contact us today to discuss your vision.</p>
        <a href="/contact" className="cta-button">Get a Free Consultation</a>
      </section>
    </section>
  );
}

export default WebDevelopment;
