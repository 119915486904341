// src/pages/About/About.js
import React, { useEffect, useContext } from 'react';
import './About.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SEO from '../../components/SEO/SEO';
import teamMember1 from '../../assets/team1.jpg';
import teamMember2 from '../../assets/team2.jpg';
import teamMember3 from '../../assets/team3.jpg';
import { DarkModeContext } from '../../contexts/DarkModeContext';

function About() {
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animation happens only once when scrolling
    });
    return () => AOS.refresh(); // Cleanup to reinitialize AOS on unmount
  }, []);

  return (
    <section className={`about-section ${darkMode ? 'dark-mode' : ''}`}>
      <SEO
        title="About Us"
        description="Learn about DiceMatrix, your premier web design and management partner in Kansas City. Our mission is to create exceptional digital experiences that drive results for your business."
        image="https://dicematrix.cloud/about-banner.jpg"
        url="https://dicematrix.cloud/about"
      />
      <h2 data-aos="fade-up">About Us</h2>
      <div className="about-content">
        <div className="about-text" data-aos="fade-right">
          <p>
            Welcome to DiceMatrix, your premier web design and management partner in the Kansas City area. We are passionate about creating exceptional digital experiences that drive results. Our team of skilled designers and developers specialize in crafting websites that are not only visually stunning but also highly functional and user-friendly.
          </p>
          <p>
            Our mission is to help businesses in Kansas City establish a strong online presence, engage their audience, and achieve their goals through innovative web solutions. Whether you're a startup or an established company, we're here to elevate your digital footprint to the next level.
          </p>
        </div>
        <div className="about-image" data-aos="fade-left">
          <img
            src="https://source.unsplash.com/600x400/?team,office"
            alt="Our Team"
            loading="lazy"
          />
        </div>
      </div>

      {/* Team Section */}
      <div className="team-section" data-aos="fade-up">
        <h3>Meet Our Team</h3>
        <div className="team-container">
          <div className="team-member" data-aos="zoom-in">
            <img
              src={teamMember1}
              alt="Jane Doe, CEO of DiceMatrix - Web Design Kansas City"
              loading="lazy"
            />
            <h4>Jane Doe</h4>
            <p>CEO & Founder</p>
          </div>
          <div className="team-member" data-aos="zoom-in" data-aos-delay="200">
            <img
              src={teamMember2}
              alt="John Smith, Lead Developer at DiceMatrix - Kansas City Web Development"
              loading="lazy"
            />
            <h4>John Smith</h4>
            <p>Lead Developer</p>
          </div>
          <div className="team-member" data-aos="zoom-in" data-aos-delay="400">
            <img
              src={teamMember3}
              alt="Emily Johnson, UX Designer at DiceMatrix - Kansas City"
              loading="lazy"
            />
            <h4>Emily Johnson</h4>
            <p>UX Designer</p>
          </div>
        </div>
      </div>

      {/* Company Timeline Section */}
      <div className="timeline-section" data-aos="fade-up">
        <h3>Our Journey</h3>
        <div className="timeline">
          <div className="timeline-item" data-aos="fade-right">
            <div className="timeline-year">2018</div>
            <div className="timeline-content">
              <h4>Founded DiceMatrix</h4>
              <p>Started as a small web design studio in Kansas City, focusing on local businesses.</p>
            </div>
          </div>
          <div className="timeline-item" data-aos="fade-left">
            <div className="timeline-year">2020</div>
            <div className="timeline-content">
              <h4>Expanded Services</h4>
              <p>Introduced website management and SEO optimization services to better serve our clients.</p>
            </div>
          </div>
          <div className="timeline-item" data-aos="fade-right">
            <div className="timeline-year">2023</div>
            <div className="timeline-content">
              <h4>Reached 100 Clients</h4>
              <p>Celebrated helping over 100 businesses enhance their online presence in Kansas City.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
