// src/components/SEO/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

function SEO({ title, description, keywords, image, url, address, telephone }) {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title} | DiceMatrix - Web Design & Management in Kansas City</title> 
      {/* Update 'DiceMatrix - Web Design & Management in Kansas City' if your brand name or tagline changes */}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={url} />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={`${title} | DiceMatrix`} />
      {/* Update 'DiceMatrix' to your business name if needed */}
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta name="twitter:title" content={`${title} | DiceMatrix`} />
      {/* Update 'DiceMatrix' to your business name if needed */}
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Structured Data */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "DiceMatrix", 
            /* ----------------------------------------Update 'DiceMatrix' to your business name */
            "url": "https://dicematrix.cloud",
            /* ---------------------------------------------------Below Variables fill at bottom */
            "logo": "${image}",
            "description": "${description}",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "${address.streetAddress}",
              "addressLocality": "${address.addressLocality}",
              "addressRegion": "${address.addressRegion}",
              "postalCode": "${address.postalCode}",
              "addressCountry": "${address.addressCountry}"
            },
            "telephone": "${telephone}",
            "openingHours": "Mo-Fr 09:00-18:00",
            /* --------------------------------------Update opening hours to match your business */
            "sameAs": [
              "https://www.facebook.com/dicematrix",
              /* -----------------------------------------Replace with your Facebook profile URL */
              "https://www.twitter.com/dicematrix",
              /* ------------------------------------------Replace with your Twitter profile URL */
              "https://www.linkedin.com/company/dicematrix"
              /* ---------------------------------Replace with your LinkedIn company profile URL */
            ]
          }
        `}
      </script>
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  address: PropTypes.shape({
    streetAddress: PropTypes.string,
    addressLocality: PropTypes.string,
    addressRegion: PropTypes.string,
    postalCode: PropTypes.string,
    addressCountry: PropTypes.string,
  }),
  telephone: PropTypes.string,
};

SEO.defaultProps = {
  /* Default Meta Information */
  title: 'DiceMatrix',
  /* Replace 'DiceMatrix' with your default title (e.g., your brand name) */
  description: 'Professional web design and management services in Kansas City.',
  /* Replace with your default meta description */
  keywords: 'Web Design Kansas City, Website Management Kansas City, SEO Services Kansas City, Digital Marketing Kansas City',
  /* Update with relevant default keywords */
  image: 'https://dicematrix.cloud/logo.png',
  /* Replace with your default logo image URL */
  url: 'https://dicematrix.cloud/',
  /* Replace with your default website URL */
  address: {
    streetAddress: '1234 Main St',
    /* Replace '1234 Main St' with your actual street address */
    addressLocality: 'Kansas City',
    /* Replace 'Kansas City' with your city */
    addressRegion: 'MO',
    /* Replace 'MO' with your state abbreviation */
    postalCode: '64101',
    /* Replace '64101' with your postal/ZIP code */
    addressCountry: 'US',
    /* Replace 'US' with your country code */
  },
  telephone: '+1-816-123-4567',
  /* Replace '+1-816-123-4567' with your business phone number */
};

export default SEO;
