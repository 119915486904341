// src/pages/Services/SEO/SEOServices.js
import React, { useEffect, useContext } from 'react';
import './SEOServices.css';
import SEO from '../../../components/SEO/SEO';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DarkModeContext } from '../../../contexts/DarkModeContext';

function SEOServices() {
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section className={`seo-section ${darkMode ? 'dark-mode' : ''}`}>
      <SEO
        title="SEO Services"
        description="Boost your website's visibility with DiceMatrix's SEO Services in Kansas City. We implement effective strategies to improve your search engine rankings."
        image="https://dicematrix.cloud/seo-services-banner.jpg" // Replace with your banner image URL
        url="https://dicematrix.cloud/services/seo-services"
      />

      <h2 data-aos="fade-up">SEO Services</h2>
      <div className="seo-content">
        <div className="seo-text" data-aos="fade-right">
          <p>
            Search Engine Optimization (SEO) is essential for increasing your website's visibility and attracting more organic traffic. At DiceMatrix, our SEO Services are tailored to meet the unique needs of your business in Kansas City.
          </p>
          <p>
            We employ a combination of on-page and off-page SEO techniques, including keyword research, content optimization, backlink building, and technical SEO audits. Our goal is to improve your website's ranking on search engine results pages (SERPs), driving more qualified leads to your business.
          </p>
        </div>
        <div className="seo-image" data-aos="fade-left">
          <img src="https://source.unsplash.com/600x400/?seo,search" alt="SEO Services" loading="lazy" />
        </div>
      </div>
    </section>
  );
}

export default SEOServices;
