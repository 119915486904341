// src/pages/Services/MobileOptimization/MobileOptimization.js
import React, { useEffect, useContext } from 'react';
import './MobileOptimization.css';
import SEO from '../../../components/SEO/SEO';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DarkModeContext } from '../../../contexts/DarkModeContext';

function MobileOptimization() {
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section className={`mobileopt-section ${darkMode ? 'dark-mode' : ''}`}>
      <SEO
        title="Mobile Optimization"
        description="Enhance your website's performance on mobile devices with DiceMatrix's Mobile Optimization services in Kansas City."
        image="https://dicematrix.cloud/mobile-optimization-banner.jpg" // Replace with your banner image URL
        url="https://dicematrix.cloud/services/mobile-optimization"
      />

      <h2 data-aos="fade-up">Mobile Optimization</h2>
      <div className="mobileopt-content">
        <div className="mobileopt-text" data-aos="fade-right">
          <p>
            In today's mobile-first world, having a website that performs seamlessly on smartphones and tablets is crucial. At DiceMatrix, our Mobile Optimization services ensure that your website provides an exceptional user experience across all mobile devices.
          </p>
          <p>
            We employ advanced techniques such as responsive design, image optimization, and efficient coding practices to enhance your website's loading speed and functionality on mobile platforms. Our goal is to increase user engagement, reduce bounce rates, and ultimately drive more conversions through a mobile-optimized website.
          </p>
        </div>
        <div className="mobileopt-image" data-aos="fade-left">
          <img src="https://source.unsplash.com/600x400/?mobile,optimization" alt="Mobile Optimization Services" loading="lazy" />
        </div>
      </div>
    </section>
  );
}

export default MobileOptimization;
