// src/pages/Contact/Contact.js
import React, { useEffect, useContext, useState } from 'react';
import './Contact.css';
import SEO from '../../components/SEO/SEO';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DarkModeContext } from '../../contexts/DarkModeContext';

function Contact() {
  const { darkMode } = useContext(DarkModeContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Initialize AOS and reapply animations
    AOS.init({
      duration: 1000,
      once: true, // Animation happens only once
    });

    // Update the root element (HTML) class based on dark mode
    document.documentElement.classList.toggle('dark-mode', darkMode);

    // Re-trigger AOS animations on dark mode change
    AOS.refresh();

    console.log(`Dark Mode Applied: ${darkMode}`);
  }, [darkMode]);

  const handleIframeLoad = () => {
    setIsLoading(false);
    console.log("Iframe loaded successfully.");
  };

  return (
    <section className={`contact-section ${darkMode ? 'dark-mode' : ''}`} data-aos="fade-up">
      <SEO
        title="Contact Us"
        description="Get in touch with DiceMatrix, your Kansas City partner for professional web design and website management services. We're here to elevate your online presence."
        image="https://dicematrix.cloud/contact-banner.jpg"
        url="https://dicematrix.cloud/contact"
        address={{
          streetAddress: '1234 Main St',
          addressLocality: 'Kansas City',
          addressRegion: 'MO',
          postalCode: '64101',
          addressCountry: 'US',
        }}
        telephone="+1-816-123-4567"
      />
      <div className="contact-form-container">
        {isLoading && (
          <div className="spinner">
            <div className="loader"></div>
            <p>Loading form...</p>
          </div>
        )}
        <iframe
          src="https://nextcloud.dicematrix.cloud/apps/forms/embed/CbDedaSt2ZwgKTQwZZYstK3M"
          title="Contact Form"
          frameBorder="0"
          className={`embedded-form ${darkMode ? 'dark-mode' : ''}`}
          width="100%"
          height="600px"
          allowFullScreen
          onLoad={handleIframeLoad}
        ></iframe>
        <noscript>
          <p>
            It seems your browser does not support JavaScript or has it disabled. Please{' '}
            <a href="mailto:admin@dicematrix.cloud">email us</a> directly.
          </p>
        </noscript>
      </div>
    </section>
  );
}

export default Contact;
