// src/pages/Services/Services.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaLaptopCode, FaMobileAlt, FaSearch, FaPaintBrush } from 'react-icons/fa';
import './Services.css';
import { DarkModeContext } from '../../contexts/DarkModeContext';

function Services() {
  const { darkMode } = useContext(DarkModeContext);

  const services = [
    {
      icon: <FaLaptopCode />,
      title: 'Web Design & Management Services',
      description: 'Building responsive and robust websites tailored to your business needs.',
      link: '/services/web-development'
    },
    {
      icon: <FaMobileAlt />,
      title: 'Mobile Optimization',
      description: 'Ensuring your website looks great and functions seamlessly on all mobile devices.',
      link: '/services/mobile-optimization'
    },
    {
      icon: <FaSearch />,
      title: 'SEO Services',
      description: 'Improving your website’s visibility on search engines to attract more visitors.',
      link: '/services/seo-services'
    },
    {
      icon: <FaPaintBrush />,
      title: 'Graphic Design',
      description: 'Creating visually appealing graphics and layouts for your brand identity.',
      link: '/services/graphic-design'
    }
  ];

  return (
    <section className={`services-section ${darkMode ? 'dark-mode' : ''}`}>
      <h2>Our Services</h2>
      <div className="services-container">
        {services.map((service, index) => (
          <Link to={service.link} className="service-card" key={index} data-aos="fade-up" data-aos-delay={index * 200}>
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </Link>
        ))}
      </div>
    </section>
  );
}

export default Services;
