// src/components/Footer/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import Logo from '../../assets/logo.png'; // Ensure the logo is in the correct path

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        {/* Footer Logo Section */}
        <div className="footer-logo">
          <Link to="/" aria-label="DiceMatrix Home">
            <img src={Logo} alt="DiceMatrix Logo" />
          </Link>
          <p className="footer-tagline">Empowering your digital future</p>
        </div>

        {/* Footer Links */}
        <div className="footer-links">
          <h3>Quick Links</h3>
          <Link to="/about" aria-label="About Us">About</Link>
          <Link to="/services" aria-label="Services">Services</Link>
          <Link to="/portfolio" aria-label="Portfolio">Portfolio</Link>
          <Link to="/contact" aria-label="Contact">Contact</Link>
        </div>

        {/* Social Links */}
        <div className="footer-social">
          <h3>Follow Us</h3>
          <a href="https://www.facebook.com/dicematrix" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            Facebook
          </a>
          <a href="https://www.twitter.com/dicematrix" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            Twitter
          </a>
          <a href="https://www.linkedin.com/company/dicematrix" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            LinkedIn
          </a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} DiceMatrix. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
