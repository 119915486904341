// src/pages/Portfolio/Portfolio.js
import React, { useEffect } from 'react';
import './Portfolio.css';
import SEO from '../../components/SEO/SEO';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import project images
import Project1 from '../../assets/project1.png';
import Project2 from '../../assets/project2.png';
import Project3 from '../../assets/project3.png';
import Project4 from '../../assets/project4.png';

function Portfolio() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animation happens only once when scrolling down
    });
  }, []);

  return (
    <section className="portfolio-page">
      <SEO
        title="Portfolio"
        description="Explore our portfolio of completed projects, showcasing our expertise in web development, mobile optimization, SEO, and graphic design."
        image="https://dicematrix.cloud/portfolio-banner.jpg"
        url="https://dicematrix.cloud/portfolio"
      />

      <div className="portfolio-header" data-aos="fade-in">
        <h1>Our Portfolio</h1>
        <p>Take a look at some of the amazing projects we've worked on.</p>
      </div>

      <div className="portfolio-container" data-aos="fade-up">
        <div className="portfolio-item">
          <img src={Project1} alt="Project One" className="portfolio-image" />
          <div className="portfolio-overlay">
            <h4>Dice | Mods</h4>
            <p>A example of a from the ground up react/node.js buildout.</p>
          </div>
        </div>
        <div className="portfolio-item">
          <img src={Project2} alt="Project Two" className="portfolio-image" />
          <div className="portfolio-overlay">
            <h4>DiceMatrix | API Hub</h4>
            <p>A static site built using 11ty, javascript framework</p>
          </div>
        </div>
        <div className="portfolio-item">
          <img src={Project3} alt="Project Three" className="portfolio-image" />
          <div className="portfolio-overlay">
            <h4>Garcia Family Medicine</h4>
            <p>Local Doctor office using squarespace.</p>
          </div>
        </div>
        <div className="portfolio-item">
          <img src={Project4} alt="Project Four" className="portfolio-image" />
          <div className="portfolio-overlay">
            <h4>Dice | Mods</h4>
            <p>An example site made with drupal cms</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
